import React, { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Typography, TextField, Link, Button, Box, Grid2 as Grid, Card, CardMedia, Stack, Divider, Avatar, Skeleton, List, ListItem, ListItemIcon, ListItemText, Dialog, DialogContent, DialogTitle, IconButton, Paper } from "@mui/material";
import StickyBar from '../lib/stickybar';
import CloseIcon from '@mui/icons-material/Close';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { MapContainer, TileLayer, Polygon, useMap } from 'react-leaflet';

const SDG_COLORS = {
  "sdg_icon_1.svg": "#e5243b",
  "sdg_icon_2.svg": "#dda83a",
  "sdg_icon_3.svg": "#4c9f38",
  "sdg_icon_4.svg": "#c5192d",
  "sdg_icon_5.svg": "#ff3a21",
  "sdg_icon_6.svg": "#26bde2",
  "sdg_icon_7.svg": "#fcc30b",
  "sdg_icon_8.svg": "#a21942",
  "sdg_icon_9.svg": "#fd6925",
  "sdg_icon_10.svg": "#dd1367",
  "sdg_icon_11.svg": "#fd9d24",
  "sdg_icon_12.svg": "#bf8b2e",
  "sdg_icon_13.svg": "#3f7e44",
  "sdg_icon_14.svg": "#0a97d9",
  "sdg_icon_15.svg": "#56c02b",
  "sdg_icon_16.svg": "#00689d",
  "sdg_icon_17.svg": "#19486a"
};

const HomePage = ({ mode, setCompanyData, apiUrl }) => {
  const { projectId: projectIdParam, voucherId: voucherIdParam } = useParams();
  const [loading, setLoading] = useState(true);
  const [voucherId, setVoucherId] = useState("");
  const [voucherData, setVoucherData] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [open, setOpen] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const polygonRef = useRef(null);
  const mapRef = useRef(null);

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const resetState = () => {
    setLoading(true);
    setVoucherId("");
    setCompanyData(null);
    setVoucherData(null);
    setProjectData(false);
    setOpen(false);
    setEmail("");
    setEmailError(false);
    setButtonDisabled(false);
  };

  useEffect(() => {
    resetState();

    if (projectIdParam && voucherIdParam) {
      setVoucherId(voucherIdParam);

      fetch(`${apiUrl}/project-voucher?projectId=${projectIdParam}&voucherId=${voucherIdParam}`)
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => {
          // Handle voucher redeemed redirection
          if (data.voucher.redeemedAt !== null) {
            resetState();
            navigate(`/voucher/${data.voucher.nanoId}/thank-you`);
          }
          setCompanyData(data.company);
          setVoucherData(data.voucher);
          setProjectData(data.project);
          setLoading(false);
        })
        .catch((err) => {
          resetState();
          navigate('/');
          window.scrollTo(0, 0);
        });
    } else {
      navigate(`/`);
      window.scrollTo(0, 0);
    }
  }, [projectIdParam, voucherIdParam, navigate, setCompanyData]);

  // Handle the redeem button click
  const handleRedeemClick = () => {
    setOpenEmail(true); // Show the popup
  };

  // Handle email validation and navigation
  const handleEmailSubmit = () => {
    if (validateEmail(email)) {
      if (!loading && voucherId) {
        // TODO add loading animation
        // call API to redeem with email
        fetch(`${apiUrl}/redeem?projectId=${projectData.id}&voucherId=${voucherId}&email=${email}`)
          .then((res) => {
            if (!res.ok) {
              throw new Error();
            }
            return res.json();
          })
          .then((data) => {
            setOpenEmail(false); // Close the popup
            navigate(`/voucher/${voucherId}/thank-you`);
            window.scrollTo(0, 0);
          })
          .catch((err) => {
            // TODO handle error
            setOpenEmail(false); // Close the popup
            console.error(err);
          });
      }
    } else {
      // Optionally show an error message for invalid email
      setEmailError(true);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleVoucherChange = () => {
    resetState();
    navigate('/');
  };

  const MapWithPolygon = () => {
    const map = useMap();

    useEffect(() => {
      if (polygonRef.current && map) {
        const bounds = polygonRef.current.getBounds();
        map.fitBounds(bounds);
      }
    }, [map]);

    return (
      <Polygon
        ref={polygonRef}
        positions={projectData?.gpsCoordinates.coordinates.map(coord => [coord.lat, coord.lng]) || []}
        color="#5fc69c"
        fillColor="#5fc69c"
        fillOpacity={0.7}
      />
    );
  };

  // Project page
  return (
    <Container style={{ textAlign: "left" }}>
      {
        loading ?
          <></>
          :
          <>
            <StickyBar makeImpactButton={handleRedeemClick} projectData={projectData} voucherData={voucherData} />
          </>
      }

      <Dialog open={openEmail} onClose={() => setOpenEmail(false)}>
        <DialogTitle>
          <span className="gilroy">Enter your email</span>
          <IconButton
            aria-label="close"
            onClick={() => setOpenEmail(false)}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>

          <Typography variant="body">
            Redeem your voucher and receive the impact order confirmation
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              marginTop: "20px",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              width: "100%",
            }}
          >
            <TextField
              fullWidth
              label={emailError ? "Invalid email address." : "Enter your email and track your impact."}
              variant="outlined"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              disabled={buttonDisabled}
              sx={{
                marginRight: { xs: "0", md: "10px" },
                marginBottom: { xs: "10px", md: "0" },
                flex: { md: 8 },
                width: { xs: "100%", md: "auto" },
                input: { textAlign: "center" },
                // Root class for the input field
                "& .MuiOutlinedInput-root": {
                  height: "80px", // Ensures the entire TextField is 80px tall
                  display: "flex",
                  alignItems: "center",
                  color: "#5fc69c",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "14px",
                  fontWeight: "bold",
                },
                // Adjust the input area to fill the space
                "& .MuiOutlinedInput-input": {
                  height: "100%", // Fill the available height
                  padding: "0 14px", // Adjust padding for centering
                  textAlign: "center", 
                },
                "& .MuiInputLabel-outlined": {
                  fontFamily: "Gilroy-Regular",
                  fontSize: "14px",
                  paddingTop: "10px",
                },
              }}
              // Handle error state
              error={emailError}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleEmailSubmit}
              disabled={buttonDisabled}
              sx={{
                height: "80px",
                flex: { md: 2 },
                width: { xs: "100%", md: "auto" },
                fontFamily: "Gilroy-Regular",
                fontSize: "1.2rem",
                textTransform: "none",
                background: 'linear-gradient(98.72deg, rgb(29, 202, 155) 9.24%, rgb(29, 202, 36) 87.42%)',
                '&:hover': {
                  background: 'linear-gradient(96deg, rgb(29, 77, 202) 19.45%, rgb(56, 207, 198) 87.31%)',
                },
              }}
            >
            Redeem
          </Button>
        </Box>
        <br />
        <Link href="https://handprint.tech/privacy-policy/" target="_blank" rel="noopener">
          Privacy Policy
        </Link>
        </DialogContent>
      </Dialog>
      <Typography className="gilroy-bold gradient-text-animation" variant="h3" component="h1" gutterBottom>
        {
          loading
            ? <>
              <Skeleton variant="text" height={60} width="100%" />
              <Skeleton variant="text" height={60} width="80%" style={{ margin: '0 auto' }} />
            </>
            : <></>
        }
      </Typography>
      <Typography className={`gilroy text-medium ${mode === 'light' ? 'light' : ''}`} component="p">
        {
          loading
            ? <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="10%" className="skeleton-primary" />
                <Skeleton variant="text" width="28%" />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', width: '50%', justifyContent: 'space-between' }}>
                  <Skeleton variant="text" width="48%" />
                  <Skeleton variant="text" width="18%" className="skeleton-primary" />
                  <Skeleton variant="text" width="10%" />
                  <Skeleton variant="text" width="18%" className="skeleton-primary" />
                </div>
              </div>
            </>
            :
            <></>
        }
      </Typography>
      {
        loading
          ?
          <></>
          :
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end" // Align content to the right
              sx={{ width: "100%" }}
            >
              <Typography variant="h6" sx={{ marginRight: '10px' }}>
                Voucher: {voucherId}
              </Typography>
              <Button
                variant="outlined"
                onClick={handleVoucherChange}
                sx={{
                  textTransform: "none",
                  borderColor: "#D3D3D3",
                  backgroundColor: "white",
                  color: "#1D4648",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  '&:hover': {
                    borderColor: "#A9A9A9",
                  }
                }}
              >
                Redeem another voucher
              </Button>
            </Box>
          </>
      }
      <Grid container id="project-about" spacing={2} style={{ marginTop: "20px", marginBottom: "50px" }}>
        <Grid item size={{ xs: 12, sm: 12, md: 12 }}>
          <Card variant="outlined">
            {
              loading
                ? <Skeleton variant="rectangular" height={300} />
                : <CardMedia component="img" height="300" image={projectData.imageUrl} alt={projectData.name} />
            }
            <Box sx={{ p: 2 }}>
              <Typography gutterBottom variant="subtitle" component="div">
                {loading ?
                  <Skeleton variant="text" width="80%" />
                  :
                  <>
                    {projectData.name} in {projectData.location} with&nbsp;
                    <Link href={projectData.partner.website} target="_blank" rel="noopener noreferrer">
                      {projectData.partner.name}
                    </Link>
                  </>
                }
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: 2 }}>
                {loading ? <Skeleton variant="text" width="80%" /> : projectData.description}
              </Typography>
              {/* {loading ? '' : (
                <Box mt={2} mb={2}>
                  <Chip
                    className="chip-primary"
                    icon={<PublicIcon style={{ color: 'white' }} />}
                    label={`${projectData.impactUnit.unitVerb} ~${Math.round(voucherData.currencyAmount / projectData.impactUnit.price)} ${projectData.impactUnit.unitNoun}`}
                  />
                </Box>
              )} */}
              {loading ? '' : (
                <>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      p: 2,
                      marginTop: 1,
                      width: '100%',
                      flexDirection: { xs: 'column', md: 'row' }, // Stack vertically on mobile
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        width: { xs: '100%', md: '80%' },  // 80% width on desktop, full width on mobile
                        textAlign: { xs: 'center', md: 'right' },  // Center text on mobile, left on desktop
                        marginBottom: { xs: '10px', md: '0' },  // Space between text and button on mobile
                        paddingRight: { xs: '0', md: '20px' },  // Space between text and button on desktop
                      }}
                    >
                      This voucher would contribute for <strong>~
                        {Math.round(voucherData.currencyAmount / projectData.impactUnit.price)}
                        {Math.round(voucherData.currencyAmount / projectData.impactUnit.price) > 1
                          ? projectData.impactUnit.unitNounPlural
                          : projectData.impactUnit.unitNounSingle}
                      </strong>
                    </Typography>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleRedeemClick}
                      sx={{
                        width: { xs: '100%', md: '20%' },  // 20% width on desktop, full width on mobile
                        height: "60px",
                        fontFamily: "Gilroy-Bold",
                        fontSize: "1.2rem",
                        textTransform: "none",
                        background: 'linear-gradient(98.72deg, rgb(29, 202, 155) 9.24%, rgb(29, 202, 36) 87.42%)',
                        '&:hover': {
                          background: 'linear-gradient(96deg, rgb(29, 77, 202) 19.45%, rgb(56, 207, 198) 87.31%)',
                        },
                      }}
                    >
                      Redeem
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Card>
        </Grid>
        <Grid id="project-location" item size={{ xs: 12, sm: 12, md: 12 }} sx={{ mb: 2 }}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="title" gutterBottom>
              {loading ? <Skeleton variant="text" width="80%" /> : <>
                <Box display="flex" justifyContent="left" alignItems="left" mb={1}>
                  Project Location
                </Box>
              </>}
            </Typography>
            {
              loading
                ? <Skeleton variant="rectangular" height={200} sx={{ bgcolor: 'grey.300' }} />
                : <MapContainer
                  scrollWheelZoom={false}
                  style={{ height: '400px', width: '100%' }}
                  ref={mapRef}
                >
                  <TileLayer
                    url="https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    attribution='&copy; <a href="https://www.esri.com/">Esri</a>'
                  />
                  <TileLayer
                    url="https://services.arcgisonline.com/arcgis/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}"
                    attribution='&copy; <a href="https://www.esri.com/">Esri</a>'
                  />
                  <MapWithPolygon />
                </MapContainer>
            }
            <Typography variant="body2" align="left">
              {loading ? <Skeleton variant="text" width="80%" /> : <>
                Project based in {projectData.location} (Realm: {projectData.realm})
              </>}
            </Typography>
          </Paper>
        </Grid>

        {/* Scorecard */}
        <Grid id="project-score" item xs={12} sm={12} md={12} sx={{ mb: 2 }}>
          <Paper elevation={3} sx={{ p: 3 }}>
            {loading ? (
              <Skeleton variant="rectangular" height={200} sx={{ bgcolor: 'grey.300' }} />
            ) : (
              <>
                <Typography variant="title" textAlign="left">Scorecard</Typography>
                <center>
                  <Box
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "80%",
                      },
                      borderRadius: 8,
                      overflow: "hidden",
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={projectData.scoreCard}
                      alt="Project score card"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        cursor: "pointer",
                      }}
                      onClick={handleClickOpen}
                    />
                  </Box>
                </center>
                <Dialog open={open} onClose={handleClose} maxWidth="md">
                  <DialogTitle>
                    <span className="gilroy">Learn how we've built the Regeneration Score</span>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent dividers>
                    <img
                      src="/img/scienticfic-assessment.png"
                      alt="Full project score card"
                      style={{ width: "100%", display: "block" }}
                    />
                  </DialogContent>
                </Dialog>
              </>
            )}
          </Paper>
        </Grid>

        {/* Benefits */}
        <Grid id="project-benefits" item size={{ xs: 12, sm: 12, md: 12 }} sx={{ mb: 2 }}>
          <Paper elevation={3} sx={{ p: 3, pb: 8 }}>
            <Typography variant="title" textAlign="left" gutterBottom sx={{ mb: 3 }}>
              {
                loading ? <Skeleton variant="text" width="80%" /> : "Benefits"
              }
            </Typography>
            <Grid container spacing={2} sx={{ pt: 2, pb: 2 }}>
              <Grid item xs={12} sm={6} md={6} sx={{ maxWidth: { xs: '100%', md: '48%' }, wordBreak: 'break-word' }}>
                <Typography variant="subtitle" align="left">
                  {
                    loading ? <Skeleton variant="text" width="80%" /> : "Why it matters locally"
                  }
                </Typography>
                <List>
                  {loading
                    ? (
                      <>
                        <Skeleton variant="text" width="17%" />
                        <Skeleton variant="text" width="80%" />
                      </>
                    ) :
                    <>
                      <Typography variant="body2" align="left">{projectData.localImpact}</Typography>
                    </>
                  }
                </List>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ maxWidth: { xs: '100%', md: '48%' }, wordBreak: 'break-word' }}>
                <Typography variant="subtitle" align="left">
                  {
                    loading ? <Skeleton variant="text" width="80%" /> : "Why it matters globally"
                  }
                </Typography>
                <List>
                  {loading
                    ? (
                      <>
                        <Skeleton variant="text" width="17%" />
                        <Skeleton variant="text" width="80%" />
                      </>
                    ) :
                    <>
                      <Typography variant="body2" align="left">{projectData.globalImpact}</Typography>
                    </>
                  }
                </List>
              </Grid>
            </Grid>
            {/* V2 - API response must include Benefits */}
            {/* <Grid container spacing={3}>
              {loading ? (
                Array.from(new Array(2)).map((_, index) => (
                  <Grid item size={{ xs: 12, sm: 6, md: 6 }}>
                    <Box>
                      <Skeleton variant="text" width="80%" />
                      <Skeleton variant="rectangular" height={100} />
                    </Box>
                  </Grid>
                ))
              ) : (
                MockProject.benefits.map(benefit => (
                  <Grid item size={{ xs: 12, sm: 6, md: 6 }}>
                    <Box textAlign="center">
                      <Box display="flex" justifyContent="center" alignItems="center" mb={1}>
                        <PublicIcon sx={{ fontSize: '1.5rem', mr: 1, pb: "1px" }} />
                        <Typography variant="h6">{benefit.name}</Typography>
                      </Box>
                      <Typography variant="body2">{benefit.description}</Typography>
                    </Box>
                  </Grid>
                ))
              )}
            </Grid> */}
          </Paper>
        </Grid>

        {/* SDGs */}
        <Grid id="project-sdgs" item size={{ xs: 12, sm: 12, md: 12 }} sx={{ mb: 2 }}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="title" textAlign="left" gutterBottom sx={{ mb: 3 }}>
              {
                loading ? <Skeleton variant="text" width="80%" /> : "The Sustainable Development Goals"
              }
            </Typography>
            <Grid container spacing={3}>
              {loading ? (
                Array.from(new Array(2)).map((_, index) => (
                  <Grid item size={{ xs: 12, sm: 12, md: 12 }}>
                    <Box>
                      <Skeleton variant="text" width="80%" />
                      <Skeleton variant="rectangular" height={100} />
                    </Box>
                  </Grid>
                ))
              ) : (
                projectData.projectSDGs.map(SDG => {
                  const imageName = SDG.imgUrl.substring(SDG.imgUrl.lastIndexOf('/') + 1);
                  const color = SDG_COLORS[imageName] || "#ffffff";
                  return (
                    <Grid item xs={12} sm={6} md={6} sx={{ maxWidth: { xs: '100%', md: '48%' }, wordBreak: 'break-word' }}>
                      <Paper className="paper-sdg" elevation={3} sx={{ p: 3, backgroundColor: color }}>
                        <Grid container spacing={2}>
                          <Grid item size={{ xs: 12, sm: 4, md: 4 }}>
                            <Box textAlign="center">
                              <img src={SDG.imgUrl} alt={SDG.name} style={{ width: "100%" }} />
                            </Box>
                          </Grid>
                          <Grid item size={{ xs: 12, sm: 8, md: 8 }}>
                            <Box display="flex" alignItems="center" height="100%">
                              <Typography variant="body2" textAlign="left" width="100%" sx={{ color: "#fff" }}>
                                {SDG.description}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Paper>
        </Grid>
        <Grid id="project-team" container item xs={12} sx={{ mb: 2 }}>
          <Paper elevation={3} sx={{ padding: 3, mb: 2 }}>
            <Typography variant="title" textAlign="left" gutterBottom sx={{ mb: 3 }}>
              {
                loading ? <Skeleton variant="text" width="80%" /> :
                  <>
                    Meet the people at {projectData.partner.name}
                  </>
              }
            </Typography>

            {/* Partner */}
            <Grid container spacing={3} sx="mt:40px;">
              {loading ? (
                Array.from(new Array(2)).map((_, index) => (
                  <Grid item xs={12} sm={12} md={12} key={index}>
                    <Stack direction="row" spacing={2} alignItems="center" mb={2}>
                      <Skeleton variant="circular" sx={{ width: 56, height: 56 }} />
                      <Box sx={{ flex: 1 }}>
                        <Typography gutterBottom variant="title" component="div" textAlign="left">
                          <Skeleton variant="text" width="80%" />
                          <Skeleton variant="rectangular" height={100} />
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                ))
              ) : (
                <Grid item size={{ xs: 12, sm: 12, md: 12 }} sx={{ pb: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item size={{ xs: 12, sm: 2, md: 2 }}>
                      <Box textAlign={{ xs: 'center', sm: 'left' }}>
                        <Link href={projectData.partner.website} target="_blank" rel="noopener noreferrer">
                          <Box
                            component="img"
                            alt={projectData.partner.name}
                            src={projectData.partner.logoUrl}
                            sx={{
                              width: 120,
                              height: 120,
                              maxWidth: '100%',
                              maxHeight: '100%',
                              margin: { xs: '0 auto', sm: '0' },
                              objectFit: 'contain'
                            }}
                          />
                        </Link>
                      </Box>
                    </Grid>
                    <Grid item size={{ xs: 12, sm: 10, md: 10 }} sx={{ pl: 2 }}>
                      <Box display="flex" flexDirection="column" justifyContent="center" height="100%" textAlign="left">
                        <Typography variant="body2">
                          {projectData.partner.description}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Divider />

            {/* Achievements & Awards */}
            <Grid container spacing={2} sx={{ pt: 2, pb: 2 }}>
              <Grid item xs={12} sm={6} md={6} sx={{ maxWidth: { xs: '100%', md: '48%' }, wordBreak: 'break-word' }}>
                <Typography variant="h6" align="left">
                  {
                    loading ? <Skeleton variant="text" width="80%" /> : "Achievements"
                  }
                </Typography>
                <List>
                  {loading
                    ? (
                      <>
                        <Skeleton variant="text" width="17%" />
                        <Skeleton variant="text" width="80%" />
                      </>
                    ) : (
                      Array.from(projectData.partner.achievements).map((achievement) => (
                        <ListItem disablePadding key={achievement.description}>
                          <ListItemIcon>
                            <EmojiEventsIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={achievement.description}
                            primaryTypographyProps={{ sx: { color: 'text.secondary', fontSize: 'body2.fontSize' } }}
                          />
                        </ListItem>
                      ))
                    )}
                </List>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ maxWidth: { xs: '100%', md: '48%' }, wordBreak: 'break-word' }}>
                <Typography variant="h6" align="left">
                  {
                    loading ? <Skeleton variant="text" width="80%" /> : "Awards"
                  }
                </Typography>
                <List>
                  {loading
                    ? (
                      <>
                        <Skeleton variant="text" width="17%" />
                        <Skeleton variant="text" width="80%" />
                      </>
                    ) : (
                      Array.isArray(projectData.partner.awards) &&
                      projectData.partner.awards.map((awards) => (
                        <ListItem disablePadding key={awards.description}>
                          <ListItemIcon>
                            <EmojiEventsIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={awards.description}
                            primaryTypographyProps={{ sx: { color: 'text.secondary', fontSize: 'body2.fontSize' } }}
                          />
                        </ListItem>
                      ))
                    )}
                </List>
              </Grid>
            </Grid>
            <Divider />

            {/* People */}
            <Grid container spacing={3}>
              <br />
              {loading ? (
                Array.from(new Array(2)).map((_, index) => (
                  <Grid item size={{ xs: 12, sm: 12, md: 12 }}>
                    <Stack direction="row" spacing={2} alignItems="center" mb={2}>
                      <Skeleton variant="circular" sx={{ width: 56, height: 56 }} />
                      <Box sx={{ flex: 1 }}>
                        <Typography gutterBottom variant="title" component="div" textAlign="left">
                          <Skeleton variant="text" width="80%" />
                          <Skeleton variant="rectangular" height={100} />
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                ))
              ) : (
                projectData.people.map(people => {
                  return (
                    <Grid item size={{ xs: 12, sm: 12, md: 12 }} sx={{ pb: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item size={{ xs: 12, sm: 2, md: 2 }}>
                          <Box textAlign={{ xs: 'center', sm: 'left' }}>
                            <Avatar
                              alt={people.name}
                              src={people.imgUrl}
                              sx={{ width: 120, height: 120, margin: { xs: '0 auto', sm: '0' } }}
                            />
                          </Box>
                        </Grid>
                        <Grid item size={{ xs: 12, sm: 10, md: 10 }} sx={{ pl: 2 }}>
                          <Box display="flex" flexDirection="column" justifyContent="center" height="100%" textAlign="left">
                            <Typography variant="h6" fontWeight="bold">
                              {people.name}
                            </Typography>
                            <Typography variant="body1" className="primary">
                              <b>{people.title}</b>
                            </Typography>
                            <Typography variant="body2">
                              {people.description}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomePage;
