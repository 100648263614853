import { apiUrl } from './config';
import React, { useState, useMemo, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import HomePage from "./pages/homepage";
import ProjectVoucherPage from "./pages/project-voucher-page";
import VoucherThankYouPage from "./pages/voucher-thank-you-page";
import Layout from "./layout";
import './style/app.css';

function App() {
  const [mode, setMode] = useState("light");
  const [companyData, setCompanyData] = useState(null);

  const theme = useMemo(() =>
    createTheme({
      palette: {
        mode,
        primary: {
          main: "#5fc69c",
          contrastText: "#fff"
        },
        secondary: {
          main: "#3f4eb3",
        },
        background: {
          default: mode === 'dark' ? "#121212" : "#fff",
          paper: mode === 'dark' ? "#1d1d1d" : "#fff",
        }
      },
      typography: {
        fontFamily: 'Gilroy-Medium',
        title: {
          color: '#1D4648',
          fontSize: '22px',
          fontFamily: 'Gilroy-Bold',
        },  
        subtitle: {
          color: '#1D4648',
          fontSize: '18px',
          fontFamily: 'Gilroy-Medium',
        },       
        body2: {
          color: '#666666',
          fontSize: '14px',
          fontFamily: 'Gilroy-Medium',
        },
      },
      components: {
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              background: 'rgb(128, 128, 128)',  // Grey gradient
              fontFamily: 'Gilroy-Regular',
              fontSize: '0.8rem',
              color: '#fff',  // White text for contrast
              padding: '10px',
            },
            arrow: {
              color: '#a9a9a9',  // Grey color for the arrow
            },
          },
        },
      },
      
    }), [mode]
  );

  const toggleDarkMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  useEffect(() => {
    document.body.classList.toggle('dark-mode', mode === 'dark');
  }, [mode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Layout toggleDarkMode={toggleDarkMode} mode={mode} setCompanyData={setCompanyData} companyData={companyData}>
          <Routes>
            <Route path="/" element={<HomePage mode={mode} setCompanyData={setCompanyData} apiUrl={apiUrl} />} />
            <Route path="/company/:name" element={<HomePage mode={mode} setCompanyData={setCompanyData} apiUrl={apiUrl} />} />
            <Route path="/voucher/:id" element={<HomePage mode={mode} setCompanyData={setCompanyData} apiUrl={apiUrl} />} />
            <Route
              path="/projects/:projectId/voucher/:voucherId"
              element={<ProjectVoucherPage mode={mode} setCompanyData={setCompanyData} apiUrl={apiUrl} />}
            />
            <Route path="/voucher/:voucherId/thank-you" element={<VoucherThankYouPage mode={mode} setCompanyData={setCompanyData} apiUrl={apiUrl} />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
