import { Container, Box, Typography, Skeleton, Grid2 as Grid } from "@mui/material";
import { Link } from "react-router-dom";

function Layout({ children, mode, companyData }) {
  return (
    <>
      <Container style={{ textAlign: "center", position: "relative" }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mb={2}
          mt={3}
        >
          <Link to="/" style={{ display: 'inline-block', width: '100%' }}>
            <img
              src={companyData?.logoUrl || "/img/handprint-black.svg"}
              alt="Project Logo"
              sx={{
                width: {
                  xs: "40%", // Small screens
                  md: "20%", // Medium screens and up
                },
              }}
            />
          </Link>
        </Box>
        <div>
        {children}
        </div>
        <Grid mb={2} container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Typography variant="body2" component="p" style={{ display: 'flex', color: 'black', alignItems: 'center', fontSize: '1.1rem' }}>
            This initiative is powered by
            <a href="https://handprint.tech"><img
              src={mode === 'dark'
                ? "/img/handprint-white.svg"
                : "/img/handprint-black.svg"}
              alt="Handprint"
              style={{ height: "37px" }}
            /></a>
          </Typography>
        </Grid>
        <Grid style={{ marginBottom: "100px" }}>
          <a href="https://handprint.tech/terms-conditions/" style={{ fontSize: '0.8rem' }}>
            Terms and Conditions
          </a>
        </Grid>
      </Container>
    </>
  );
}

export default Layout;