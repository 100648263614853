const HandprintProjects = [
    { id: 1, imageUrl: "/img/projects/1.png", name: "Plant trees", description: "This project aims to revegetate the landscape of the 200km Corridor and return the environment to its original state while simultaneously removing carbon from the atmosphere.", location: "Australia" },
    { id: 2, imageUrl: "/img/projects/2.png", name: "Restore coral reefs", description: "This project aims to create a vibrant coral reef ecosystem that is self-sustaining and to support the local community by increasing ecotourism.", location: "Indonesia" },
    { id: 3, imageUrl: "/img/projects/3.png", name: "Provide potable water", description: "This project provides portable and lightweight water filters for humanitarian aid, emergency response and remote deployment.", location: "Myanmar" },
    { id: 4, imageUrl: "/img/projects/4.png", name: "Cleanup ocean plastic", description: "This project enables communities, businesses, and individuals to gather discarded material from the environment and use it to end poverty.", location: "Philippines" },
    { id: 5, imageUrl: "/img/projects/5.png", name: "Provide health kits to children", description: "The health kits from this project are composed of 2 deworming tablets and 1 BPA-free water bottle, providing a simple but vital defence against infectious diseases linked to contaminated water and poor hygiene.", location: "Cambodia" },
    { id: 6, imageUrl: "/img/projects/6.png", name: "Restore mangroves", description: "This project by a non-profit organisation promotes the conservation and protection of existing coastal biodiversity and coastal forests, creating a thriving, prosperous and creative coastal community.", location: "Indonesia" },
    { id: 7, imageUrl: "/img/projects/7.png", name: "Help vulnerable women", description: "This project aims to provide the monthly cost of medical care, counselling, skills development, and enrichment programs for victims and survivors of the sex industry in Cambodia.", location: "Cambodia" },
    { id: 8, imageUrl: "/img/projects/8.png", name: "Restore critical habitats", description: "This project aims to restore unique and critically endangered habitats in high-altitude forested wetlands, and support indigenous communities dependent on these habitats for their livelihoods.", location: "India" },
    { id: 9, imageUrl: "/img/projects/9.png", name: "Support gender equality through football", description: "This project aims to help some of Cambodia’s most marginalised girls access their right to play, improve their health and gain the confidence that drives them to succeed.", location: "Indonesia" },
];

export default HandprintProjects;