import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Container, Typography, TextField, Button, Box, Grid2 as Grid, Card, CardContent, Collapse, CardMedia, CardActions, Tooltip, Chip, Skeleton } from "@mui/material";
import { MAX_DESCRIPTION_LENGTH, truncateText } from "../lib/helper";
import PublicIcon from '@mui/icons-material/Public';
import PlaceIcon from '@mui/icons-material/Place';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HandprintProjects from "../data/handprint-projects";

const HomePage = ({ mode, setCompanyData, apiUrl }) => {
  const { name: companyNameParam, id: voucherIdParam } = useParams();
  const [loading, setLoading] = useState(true);
  const [voucherId, setVoucherId] = useState("");
  const [companyData, setLocalCompanyData] = useState(null);
  const [voucherData, setVoucherData] = useState(null);
  const [voucherError, setVoucherError] = useState(false);
  const [voucherApply, setVoucherApply] = useState(false);
  const navigate = useNavigate();

  const resetState = () => {
    setLoading(true);
    setVoucherId("");
    setLocalCompanyData(null);
    setCompanyData(null);
    setVoucherData(null);
    setVoucherError(false);
    setVoucherApply(false);
  };

  const fetchData = async () => {
    if (companyNameParam) {
      fetch(`${apiUrl}/company?name=${companyNameParam}`)
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => {
          setCompanyData(data);
          setLocalCompanyData(data);
          setLoading(false);
        })
        .catch((err) => {
          // Handle company not found
          resetState();
          navigate('/');
        });
    } else if (voucherIdParam) {
      setVoucherId(voucherIdParam);
      setVoucherApply(true);

      fetch(`${apiUrl}/voucher?id=${voucherIdParam}`)
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => {
          // Handle voucher redeemed redirection
          if (data.voucher.redeemedAt !== null) {
            resetState();
            navigate(`/voucher/${data.voucher.nanoId}/thank-you`);
          }
          setCompanyData(data.company);
          setLocalCompanyData(data.company);
          setVoucherData(data.voucher);
          setLoading(false);
        })
        .catch((err) => {
          // TODO handle voucher expired or not found
          setVoucherError(true);
          setVoucherApply(false);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    resetState();
    fetchData();
  }, [companyNameParam, setCompanyData, voucherIdParam]);

  const handleButtonClick = () => {
    if (!loading && voucherId) {
      setVoucherError(false);
      setVoucherApply(true);
      navigate(`/voucher/${voucherId}`);
      window.scrollTo(0, 0);
    } else {
      setVoucherApply(false);
      setVoucherError(true);
    }
  };

  const handleVoucherChange = () => {
    resetState();
    navigate('/');
  };

  // Skeleton loading grid
  const renderSkeletonGrid = () => (
    <Grid container spacing={2} style={{ marginTop: "20px", marginBottom: "50px" }}>
      {Array.from(new Array(6)).map((_, index) => (
        <Grid item size={{ xs: 12, sm: 6, md: 4 }} key={index}>
          <Card>
            <Skeleton variant="rectangular" height={200} />
            <CardContent>
              <Skeleton variant="text" height={30} />
              <Skeleton variant="text" height={20} width="80%" />
            </CardContent>
            <CardActions>
              <Skeleton variant="text" width="10%" className="skeleton-primary" />
              <Skeleton variant="text" width="40%" />
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  // Project card
  const ProjectCard = ({ project, voucherData }) => {
    const cardContentHeight = voucherData ? 170 : 120;
    const linkTo = voucherData ? `/projects/${project.id}/voucher/${voucherData.nanoId}` : null;

    const card = (
      <Card className="card-link">
        <CardMedia
          sx={{ height: 200 }}
          image={project.imageUrl}
          title={project.name}
        />
        <CardContent sx={{ height: cardContentHeight }}>
          <Tooltip title={project.name} arrow>
            <Typography gutterBottom variant="subtitle" component="div" noWrap>
              {project.name} test
            </Typography>
          </Tooltip>
          <Tooltip title={project.description} arrow>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {truncateText(project.description, MAX_DESCRIPTION_LENGTH)}
            </Typography>
          </Tooltip>
          {voucherData && (
            <Box mt={2} mb={2}>
              <Chip
                className="chip-secondary"
                icon={<PublicIcon style={{ color: 'white' }} />}
                label={`${project.impactUnit.unitVerb} ≈${Math.round(voucherData.currencyAmount / project.impactUnit.price)} ${Math.round(voucherData.currencyAmount / project.impactUnit.price) > 1
                  ? project.impactUnit.unitNounPlural
                  : project.impactUnit.unitNounSingle
                  }`}
              />
            </Box>
          )}
        </CardContent>
        <CardActions sx={{ marginTop: 3 }}>
          <PlaceIcon color="primary" sx={{ marginRight: 1 }} /> {project.location}
        </CardActions>
        {voucherData && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
            sx={{
              width: {
                xs: "95%",  // 95% for small screens
                md: "95%",  // 50% for medium and larger screens
              },
              height: {
                xs: "80px",  // 80px for small screens
                md: "50px",  // 60px for medium and larger screens
              },
              fontFamily: "Gilroy-Regular",
              fontSize: "1.2rem",
              textTransform: "none",
              marginBottom: '10px',
              background: 'linear-gradient(98.72deg, rgb(29, 202, 155) 9.24%, rgb(29, 202, 36) 87.42%)',
              '&:hover': {
                background: 'linear-gradient(96deg, rgb(29, 77, 202) 19.45%, rgb(56, 207, 198) 87.31%)',
                '& .iconPlanet': {
                  marginLeft: '16px',
                },
              },
            }}

          >
            View
          </Button>
        )}
      </Card>
    );

    return linkTo ? (
      <Link to={linkTo} style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
        {card}
      </Link>
    ) : (
      card
    );
  };

  // Projects grid
  const renderProjectsGrid = (projects) => (
    <>
      {voucherData && (
        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
          <KeyboardArrowDownIcon className="bounce" />
          <Typography sx={{ mx: 1 }}>
            Discover projects and pick a cause to support
          </Typography>
          <KeyboardArrowDownIcon className="bounce" />
        </Box>
      )}
      <Grid container spacing={2} style={{ marginTop: "20px", marginBottom: "50px" }}>
        {projects.map((project) => (
          <Grid item size={{ xs: 12, sm: 6, md: 4 }} key={project.id}>
            <ProjectCard project={project} voucherData={voucherData} />
          </Grid>
        ))}
      </Grid>
    </>
  );

  // Homepage
  return (
    <Container style={{ textAlign: "center" }}>
      <Typography className="gilroy-bold gradient-text-animation" variant="h3" component="h1" gutterBottom>
        {
          loading
            ? <>
              <Skeleton variant="text" height={60} width="100%" />
              <Skeleton variant="text" height={60} width="80%" style={{ margin: '0 auto' }} />
            </>
            :
            <>
              Make Your Voucher Count, Restore Our Planet
            </>
        }
      </Typography>
      <Typography className={`gilroy text-medium ${mode === 'light' ? 'light' : ''}`} component="p">
        {
          loading
            ? <>
              <Skeleton variant="text" width="80%" style={{ margin: '0 auto' }} />
              <Skeleton variant="text" width="100%" />
              <Skeleton variant="text" width="70%" style={{ margin: '0 auto' }} />
              <br />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="10%" className="skeleton-primary" />
                <Skeleton variant="text" width="28%" />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', width: '50%', justifyContent: 'space-between' }}>
                  <Skeleton variant="text" width="28%" />
                  <Skeleton variant="text" width="20%" className="skeleton-primary" />
                  <Skeleton variant="text" width="50%" />
                </div>
              </div>
            </>
            : <>
              {
                companyData
                  ? (
                    <>
                      <span className="company-name gilroy-bold primary">{companyData.name} </span> invites you to turn your voucher into a force for good. Pick a cause close to your heart, and redeem your voucher value to create lasting impact that you can follow through frequent updates.
                    </>
                  )
                  : (
                    <>
                      <span className="gilroy-bold primary">Handprint</span> platform empowers you to turn vouchers into positive change for the planet. Whether it’s restoring coral reefs, planting trees, or funding education, your support creates lasting impact that you can follow through frequent updates. <br /><br />
                      Are you a business looking to stand out as a regenerative brand? Learn how you can <Link to='https://handprint.tech/solutions/impact-gifting/'>set up a gifting or rewards campaign</Link> that aligns with your sustainability goals.
                    </>
                  )
              }
            </>
        }
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          marginTop: "20px",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {/* Collapse effect when voucher is valid */}
        <Collapse in={!voucherApply} timeout={300} sx={{ width: "100%" }}>
          <Box
            display="flex"
            alignItems="center"
            flexDirection={{ xs: "column", md: "row" }} // Stack vertically on small screens, horizontally on larger screens
            sx={{ width: "100%", marginBottom: "10px" }}
          >
            <TextField
              fullWidth
              label={voucherError ? "Voucher not found or invalid." : "You got a voucher? Enter it here."}
              variant="outlined"
              value={voucherId}
              onChange={(e) => setVoucherId(e.target.value)}
              inputProps={{
                maxLength: 8,
              }}
              sx={{
                flex: 1, // Take remaining space
                marginBottom: { xs: "10px", md: "0" }, // Add margin bottom on small screens
                marginRight: { xs: "0", md: "10px" }, // Space between text field and button on larger screens
                input: { textAlign: "center" },
                "& .MuiOutlinedInput-root": {
                  color: "#5fc69c",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "2rem",
                  fontWeight: "bold",
                },
                "& .MuiInputLabel-outlined": {
                  fontFamily: "Gilroy-Regular",
                  fontSize: "1.3rem",
                  paddingTop: "10px",
                },
              }}
              error={voucherError}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleButtonClick}
              sx={{
                width: { xs: "100%", md: "20%" }, // Full width on small screens, 20% on larger screens
                height: "80px",
                fontFamily: "Gilroy-Regular",
                fontSize: "1.2rem",
                textTransform: "none",
                background: 'linear-gradient(98.72deg, rgb(29, 202, 155) 9.24%, rgb(29, 202, 36) 87.42%)',
                '&:hover': {
                  background: 'linear-gradient(96deg, rgb(29, 77, 202) 19.45%, rgb(56, 207, 198) 87.31%)',
                  '& .iconPlanet': {
                    marginLeft: '16px',
                  },
                },
              }}
            >
              Apply
            </Button>
          </Box>
        </Collapse>

        {/* Display applied voucher code */}
        {
          loading
            ?
            <></>
            :
            <><Collapse in={voucherApply} timeout={300} sx={{ width: "100%" }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end" // Align content to the right
                sx={{ width: "100%" }}
              >
                <Typography variant="title" sx={{ marginRight: '10px' }}>
                  Voucher: {voucherId}
                </Typography>
                <Button
                  variant="outlined"
                  onClick={handleVoucherChange}
                  sx={{
                    textTransform: "none",
                    borderColor: "#D3D3D3",
                    backgroundColor: "white",
                    color: "#1D4648",
                    fontFamily: "Gilroy-Bold",
                    fontSize: "16px",
                    '&:hover': {
                      borderColor: "#A9A9A9",
                    }
                  }}
                >
                  Redeem another voucher
                </Button>
              </Box>
            </Collapse>
            </>
        }
      </Box>
      {
        loading
          ? renderSkeletonGrid()
          : (companyData ? renderProjectsGrid(companyData.projects)
            : renderProjectsGrid(HandprintProjects))
      }
    </Container>
  );
};

export default HomePage;
