import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Grid2 as Grid, Container } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import useMediaQuery from '@mui/material/useMediaQuery';

const StickyBar = ({ makeImpactButton, projectData, voucherData }) => {
  const [isSticky, setIsSticky] = useState(false);

  // Check if screen is small (mobile)
  const isMobile = useMediaQuery('(max-width:600px)');

  // Function to check scroll position
  const handleScroll = () => {
    const triggerElement = document.getElementById('project-location');
    const triggerPosition = triggerElement.getBoundingClientRect().top;

    // Set sticky bar to visible when the trigger element is scrolled out of view
    if (triggerPosition <= 0) {
      setIsSticky(true);  // Show sticky bar
    } else {
      setIsSticky(false); // Hide sticky bar
    }
  };

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollTo = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      // Get the height of the sticky bar to offset the scroll position
      const stickyBarHeight = document.getElementById('sticky-bar').offsetHeight;

      // Scroll to the section minus the height of the sticky bar
      window.scrollTo({
        top: section.offsetTop - stickyBarHeight,
        behavior: 'smooth'
      });
    }
  };

  const capitalizeFirstLetter = (word) => {
    return word ? word.charAt(0).toUpperCase() + word.slice(1) : '';
  };

  // Render nothing if the bar is not sticky yet
  if (!isSticky) return null;

  return (
    <AppBar
  id="sticky-bar"
  position='fixed'
  color='default'
  elevation={4}
  style={{ 
    top: isMobile ? 'auto' : 0,  // Stick to the bottom on mobile, top on desktop
    bottom: isMobile ? 0 : 'auto'  // Conditionally set bottom for mobile screens
  }}
>
  <Container maxWidth="lg"> {/* Constrain content within container */}
    <Toolbar sx={{ justifyContent: 'space-between', width: '100%' }}>
      {/* Menu (80% width) */}
      {!isMobile && (
        <Grid container item xs={8} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button color="inherit" onClick={() => handleScrollTo('project-about')}>
            About
          </Button>
          <Button color="inherit" onClick={() => handleScrollTo('project-location')}>
            Location
          </Button>
          <Button color="inherit" onClick={() => handleScrollTo('project-score')}>
            Score
          </Button>
          <Button color="inherit" onClick={() => handleScrollTo('project-benefits')}>
            Benefits
          </Button>
          <Button color="inherit" onClick={() => handleScrollTo('project-team')}>
            Team
          </Button>
        </Grid>
      )}

      {/* Action Button (20% width for md and higher) */}
      <Grid container item xs={isMobile ? 12 : 4} sx={{ justifyContent: isMobile ? 'center' : 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={makeImpactButton}
          sx={{
            padding: '6px 16px',
            fontFamily: "Gilroy-Regular",
            fontSize: "1.2rem",
            textTransform: "none",
            background: 'linear-gradient(98.72deg, rgb(29, 202, 155) 9.24%, rgb(29, 202, 36) 87.42%)',
            '&:hover': {
              background: 'linear-gradient(96deg, rgb(29, 77, 202) 19.45%, rgb(56, 207, 198) 87.31%)',
              '& .iconPlanet': {
                marginLeft: '8px',
              },
            },
            marginLeft: isMobile ? '0' : '16px',
            whiteSpace: 'nowrap',
          }}
        >
          {capitalizeFirstLetter(projectData.impactUnit.unitVerb)} ~{Math.round(voucherData.currencyAmount / projectData.impactUnit.price)} {projectData.impactUnit.unitNoun}
          <PublicIcon
            className="iconPlanet"
            sx={{
              fontSize: '1.5rem',
              marginLeft: '8px',
              transition: 'margin-left 0.3s ease',
            }}
          />
        </Button>
      </Grid>
    </Toolbar>
  </Container>
</AppBar>


  );
};

export default StickyBar;
